.soon__container_content {
    margin: 0;
    display: flex;
    position: absolute;
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: hidden;
    justify-content: center;
}
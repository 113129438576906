.apply {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../assets/bg.png');
    background-size: 100%;
}

.apply_header {
    display: flex;
    justify-content: center;
    padding: 4rem;
    width: 100%;
    max-height: 24rem;
}

.apply_header img {
    height: 14rem;
}

.apply_form {
    display: flex;
    justify-content: center;
    height: auto;
    margin-bottom: 12rem;
}

.apply_form iframe {
    background-color: rgb(40,40,40);
    border-radius: 4rem;
    padding-top: 2rem;
}

@media screen and (max-width: 850px) {
    .apply_header {
        padding: 2rem;
    }
    .apply_header img {
        height: 8rem;
    }
}

@media screen and (max-width: 450px) {
    .apply_header {
        padding: 1rem;
        padding-bottom: 2rem;
    }
    .apply_header img {
        height: 4rem;
    }
}
.redirect {
    margin: 0;
    display: flex;
    position: absolute;
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: hidden;
    justify-content: center;
}

.course_page {
    margin: 0;
    display: flex;
    position: absolute;
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: hidden;
    justify-content: center;

    text-align: center;
    padding: 0 8rem 0 8rem;
}

.course_page a {
    text-decoration: none;

    color: #ff8989;
}

.course_page h3 {
    margin: 2rem;
}

.course_input {
    background-color: #0f0f0f;
    height: 2.5rem;
    width: 12rem;
    border-style: none;
    border-radius: 1rem;

    color: #fff;
    text-align: center;
    font-size: 28px;
    font-family: var(--font-family);
}

@media screen and (max-width: 550px) {
    .course_page {
        padding: 0 2rem 0 2rem;
        font-size: 14px;
    }
}

@media screen and (max-width: 450px) {
    .course_page {
        padding: 0 2rem 0 2rem;
        font-size: 10px;
    }
}

.redirect {
    margin: 0;
    display: flex;
    position: absolute;
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: hidden;
    justify-content: center;
}

.no_redirect,
.loading {
    margin: 0;
    display: flex;
    position: absolute;
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: hidden;
    justify-content: center;

    cursor: default;
}

.no_redirect h3 {
    margin: 2rem;
}

.no_redirect p {
    background-color: rgba(0, 0, 0, 0.5);
    display: inline-block;
    padding: .2rem;
    color: rgb(230, 230, 230);
    border-radius: .5rem;
}
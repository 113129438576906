* { /* Applies to everything */ 
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

:root {
    font-family: var(--font-family);
    color: var(--color-text);
    background: var(--color-bg);
}